.Journalview {
    display: flex;
    gap: 5vw;
    width: 75vw;
    margin-bottom: 3vw;
}

.JournalviewAside {
    margin-top: 5.5vw;
    width: 15vw;
    height: 30vw;
    border: .1vw solid #ff6400;
    border-radius: .5vw;
    overflow: hidden;
}

.JournalButton {
    display: flex;
    flex-direction: column;
}

.JournalButton a {
    text-decoration: none;
    width: 95%;
    height: 3vw;
    padding: 0 .5vw;
    border-top: .1vw solid rgba(128, 128, 128, 0.288);
    transition: all .3s ease;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .8vw;
    font-weight: bold;
    font-family: sans-serif;
    color: #ff6400;
}

.JournalButton button {
    text-decoration: none;
    width: 100%;
    height: 3vw;
    padding: 0 .5vw;
    border-top: .1vw solid rgba(128, 128, 128, 0.288);
    transition: all .3s ease;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1vw;
    font-weight: bold;
    font-family: sans-serif;
    color: #ff6400;
    border: none;
    cursor: pointer;
    background: transparent;
    border-bottom: 1px solid #ff6400;
}


.JournalButton a:hover {
    background-color: rgba(128, 128, 128, 0.103);
}

.JournalButton button:hover {
    background-color: rgba(0, 0, 0, 0.086);
    
}

.JournalasideTitle {
    width: 100%;
    height: 2vw;
    padding: 0 .5vw;
    display: flex;
    align-items: center;
    background-color: #ff6400a1;
    font-size: 1vw;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
}

.JournalContentRight {
    width: 35vw;
    display: flex;
    flex-direction: column;
    border: .1vw solid #ff6400;
    margin-top: 5.5vw;
    border-radius: 1vw;
}

.JournalContentRight p {
    text-align: center;
}

.JournalTitle h1 {
    font-size: .8vw;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    border: none;
    padding: 0;
    margin: 0;
    padding: 1vw;
}

.JournalContentContainer {
    display: flex;
    flex-direction: column;
    background: rgba(128, 128, 128, 0.13);
    padding: 1vw;
    gap: 1vw;
    border-top: .1vw solid #ff66006b;
}

.ContentContainerBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;

    background-color: white;
    padding: .5vw;
    border-radius: 1vw;
}

.ContentContainerBox img {
    width: 20%;
}

.ContentContainerBox a {
    width: 80%;
    color: #ff6400;
    font-size: .9vw;
    font-weight: bold;
    font-family: sans-serif;
    color: #ff6400;
}

.ContentContainerBox p {
    font-family: sans-serif;
    font-size: .8vw;
    display: flex;
    flex-direction: column;
    gap: .8vw;
}

.JournalGlavTitle {
    width: 22vw;
    letter-spacing: .1vw;
    text-align: center;
    margin-top: 3vw;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 1vw;
    text-transform: uppercase;
    border-bottom: .3vw solid #ff6400;
    padding-bottom: .5vw;
}

.UmumiyContentContainer {
    display: flex;
    flex-direction: column;
    background: rgba(128, 128, 128, 0);
    padding: 1vw;
    gap: 1vw;
    border-top: .1vw solid #ff66006b;
}

.UmumiyContentContainer p {
    font-size: .8vw;
    letter-spacing: .03vw;
    font-family: sans-serif;
    text-align: justify;
}