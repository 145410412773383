/* NotificationBar.css */
.notification-bar {
    background-color: #ffcc00;
    /* Yellow background */
    color: #000;
    /* Black text */
    padding: 10px;
    text-align: center;
    font-weight: bold;
    position: fixed;
    bottom: 0;

    z-index: 10;
    margin-top: 4vw;
    left: -0vw;
    border-top-right-radius: 10vw;
    border-bottom-right-radius: 10vw;

}

.notification-bar p {
    margin: 0;
}