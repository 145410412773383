.Arhiv {
    display: flex;
    flex-direction: row;
}

.ArxivContainersBoxs {
    width: 75%;
    display: flex;
    flex-direction: row;
    gap: 2vw;
    margin-top: 2vw;
    justify-content: flex-start;

}

.ArxiveBox {
    width: 15vw;
    border: .1vw solid #ff6400;
    border-radius: .5vw;
    overflow: hidden;
}

.ArxiveBox ul {
    list-style: none;
    align-items: center;
    padding: 0px 10px;
}

.ArxiveBox ul li a {
    text-decoration: none;
    width: 100%;
    height: 3vw;
    border-bottom: .1vw solid rgba(128, 128, 128, 0.288);
    transition: all .3s ease;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .8vw;
    font-weight: bold;
    font-family: sans-serif;
    color: #ff6400;
    text-align: center;
}

.ArxiveBoxs {
    width: 80%;
    height: 60vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    word-wrap: break-word;
    border: 1px solid #ff6400;
    border-radius: 5px;
    gap: 3vw;
    overflow-y: auto;
    padding: 1vw;
}

.arxiveBoxs1 {
    padding: .1vw;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.arxiveBoxs1 a {
    padding-top: 1vw;
    font-size: 1.5vw;
    color: #ff6400;
}

.arxiveBoxs1 img {
    width: 8vw;
    height: 12vw;
}

@media screen and (max-width: 768px) {
    .ArxivContainersBoxs {
        display: flex;
        flex-direction: column;
    }

    .ArxiveBoxs {
        display: flex;
        flex-direction: column;
    }

    .ArxiveBox {
        width: 80%;


    }

    .ArxiveBox ul li a {
        font-size: large;
        padding: 2vw;
    }
}