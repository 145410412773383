.TaxrirTitle {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TaxrirTitle h1 {
    width: 25vw;
    text-align: center;
    font-family: sans-serif;
    margin: 1vw 0;
    border-bottom: .2vw solid #ff6400;
    font-weight: 800;
    font-size: small;
}

.JournalContentContainer {
    display: flex;
}

.taxrirCon {
    display: flex;
    height: 100%;
}


.taxrirConImg {
    width: 8vw;
    height: 8vw;
}

.taxrirConImg img {
    width: 8vw;
    height: 8vw;
    margin-top: .8vw;

}

.taxrirRight ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: sans-serif;
}

@media screen and (max-width: 768px) {
    .taxrirConImg img {
        width: 51vw;
        height: 38vw;
    }
}