body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;
    background-color:black;
  }

  body::-webkit-scrollbar {
    width: 7px;
    background-color: black;
  }
  
  body::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.212);
    border-radius: 100px;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
    background-color: black;
  }

.App {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
}