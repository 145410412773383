.FotterContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 3vw;
    gap: 7%;
    background-color: #ff6400;
    justify-content: center;
    bottom: -1vw;
}

.FotterContainer div h4 {
    color: white;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 1vw;
}

.FotterContainer div h5 {
    font-size: .9vw;
    color: white;
    font-family: sans-serif;
    font-weight: bold;
}

.FotterContainer div p {
    color: #dddbdb;
    font-family: sans-serif;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .FotterContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 2%;
        background-color: #ff6400;
        justify-content: center;
        padding-bottom: 6vw;
    }

    .FotterContainer div {
        margin-left: 25vw;
    }

    .FotterContainer div h4 {
        color: white;
        font-family: sans-serif;
        font-weight: small;
        font-size: 3vw;
    }

    .FotterContainer div h5 {
        color: white;
        font-family: sans-serif;
        font-weight: small;
        font-size: 3vw;
    }

    .FotterContainer div p {
        color: #dddbdb;
        font-family: sans-serif;
        font-weight: bold;
        font-size: 2vw;
    }

}