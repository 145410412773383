.elonlar {
    width: 80%;
    margin: 2vw auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;   
}
.elon_title {
    font-size: 2vw;
    font-family: sans-serif;
    text-align: center;
}

.elonlar img {
    width: 35vw;
}

.loading {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    animation: blink 1.5s infinite;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.pdf-link {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
    text-decoration: none;
    color: #007bff;
}
